import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Prerequisites`}</h3>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`A Smallstep `}<a parentName="li" {...{
          "href": "https://smallstep.com/sso-ssh/#overview"
        }}>{`SSH Professional account`}</a>{`. Need one → `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`SSO and User Sync configured and functioning`}</li>
      <li parentName="ul">{`Hosts Registered with Tags`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <p>{`The following features are supported:`}</p>
    <ul>
      <li parentName="ul">{`Access control by Host Tags configuration using Groups synchronized from your IDP`}</li>
    </ul>
    <h2>{`Step By Step Instructions`}</h2>
    <h3>{`Step 0: Understanding Host Tags`}</h3>
    <p><strong parentName="p">{`Host Tags`}</strong>{` (key-value pairs) are the pillars of our access control model. Rather than mapping people or groups directly to hosts, you'll map `}<em parentName="p">{`tag combinations`}</em>{` to your hosts and to your user groups. First you'll put your hosts into logical groups using tags, eg. `}<inlineCode parentName="p">{`role`}</inlineCode>{`:`}<inlineCode parentName="p">{`web`}</inlineCode>{` or `}<inlineCode parentName="p">{`env`}</inlineCode>{`:`}<inlineCode parentName="p">{`staging`}</inlineCode>{`. Then, you'll grant user groups access to all hosts with a specific tag combination. Finally, you'll choose which user group tag combinations will allow `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` privileges on any matching hosts.`}</p>
    <p>{`Let's look at an example:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/host-tags-example.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`developers`}</inlineCode>{` group will have access to  `}<inlineCode parentName="li">{`myserver #1`}</inlineCode>{` only.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`data`}</inlineCode>{` group will have access to  `}<inlineCode parentName="li">{`myserver #2`}</inlineCode>{` and  `}<inlineCode parentName="li">{`myserver #3`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`ops`}</inlineCode>{` group will have `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` access to `}<inlineCode parentName="li">{`myserver #2`}</inlineCode>{` and `}<inlineCode parentName="li">{`myserver #3`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`Of course, hosts and groups can have as many tag combinations as you like.  Take a minute to think about how you'd like to use Host Tags in your environment.`}</p>
    <h3>{`Step 1: Determine Host Tag Combination`}</h3>
    <p>{`Sign in at `}<inlineCode parentName="p">{`https://smallstep.com/app/[Team ID]`}</inlineCode></p>
    <ul>
      <li parentName="ul">{`Choose "SSH Professional" from the top navigation bar`}</li>
      <li parentName="ul">{`Choose "Hosts" on the right hand navigation bar`}</li>
      <li parentName="ul">{`Find and select a Host that includes the Host Tags you wish to use for access grants.`}
        <ul parentName="li">
          <li parentName="ul">{`In the example below that combination is `}<inlineCode parentName="li">{`database`}</inlineCode>{` : `}<inlineCode parentName="li">{`production`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Make a note of the tag combination, you will need this in Step 2`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/acl-host-detail.png",
        "alt": null
      }}></img></p>
    <h3>{`Step 2: Grant User Group Access to Host(s)`}</h3>
    <p>{`On your Smallstep Dasbhoard.`}</p>
    <ul>
      <li parentName="ul">{`Select the "Users" tab on the right hand navigation bar.`}</li>
      <li parentName="ul">{`Choose the "GROUPS" tab`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/acl-select-idp-group.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`Select the User Group that you want to configure for access control. You will see the user group detail page with a list of existing Host Grants and a form to add additional grants.`}</li>
      <li parentName="ul">{`Select "Edit" from the kebab menu to the right of the Group's name.`}
        <ul parentName="li">
          <li parentName="ul">{`Enter the tag values from Step 1`}</li>
          <li parentName="ul">{`If Sudo access is permitted for this user group, select the "Allow Sudo" checkbox`}</li>
          <li parentName="ul">{`Click the checkbox to enable access control`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Optional`}</strong>{`. Assign POSIX Groups. Smallstep now supports POSIX groups on hosts running `}<inlineCode parentName="li">{`step-ssh`}</inlineCode>{` version 0.22.0 or greater.
`}<strong parentName="li">{`Managed Groups`}</strong>{` are POSIX groups that you want Smallstep to create or delete on your Hosts.
`}<strong parentName="li">{`Unmanaged Groups`}</strong>{` are the default POSIX groups such as  `}<strong parentName="li"><em parentName="strong">{`fax`}</em></strong>{` or  `}<strong parentName="li"><em parentName="strong">{`floppy`}</em></strong>{` that already exist on the Host. Groups members will have permissions assigned to these groups.`}
        <ul parentName="li">
          <li parentName="ul">{`In the example below, we are adding users in the Smallstep group `}<strong parentName="li">{`okta-isvtest-ssh`}</strong>{` to one unmanaged POSIX group (`}<strong parentName="li"><em parentName="strong">{`floppy`}</em></strong>{`) and one managed POSIX group (`}<strong parentName="li"><em parentName="strong">{`sre-team`}</em></strong>{`)`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Optional`}</strong>{`. Assign Certificate Principals. Smallstep now supports adding additional prinicals to user certificates. A `}<strong parentName="li">{`Principal`}</strong>{` is a string that allows access to a host, or even a specific user on a host.
Users in the assigned group will be able to access Smallstep-managed hosts as that user, whether or not the user is managed by Smallstep. Adding a user as a certificate principal does not enable full lifecycle management for that user.`}
        <ul parentName="li">
          <li parentName="ul">{`In the example below we are adding the principal `}<strong parentName="li"><em parentName="strong">{`craig`}</em></strong>{` to certificates issued for the Group `}<strong parentName="li">{`okta-isvtest-ssh`}</strong>{`.`}</li>
        </ul>
      </li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/acl-grant-access.png",
        "alt": null
      }}></img></p>
    <ul>
      <li parentName="ul">{`All users in the group now have access to all hosts with the specified tag combination.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      